<template>
  <section id="settings">
    <v-row>
      <v-col cols="12" 
      :xl="$route.name == 'Admin General Settings' ? 12 : 9" 
      :lg="$route.name == 'Admin General Settings' ? 12 : 11">
        <div class="text-h5 ml-2 mb-5">System Settings</div>
        <v-sheet class="d-flex flex-row" style="background-color: transparent">
          <v-navigation-drawer
            permanent
            mini-variant
            mini-variant-width="56"
            class="settings-drawer"
            style="background-color: transparent"
          >
            <v-list dense>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :to="{ name: item.to }"
                link
                exact
                extive-class="settings-drawer__active"
              >
                <v-list-item-content>
                  <v-list-item-icon>
                    <v-icon size="24">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-sheet width="100%" style="background-color: transparent">
            <router-view />
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    items: [
      {
        title: "",
        to: "Admin General Settings",
        icon: "mdi-cog-outline",
      },
      // {
      //   title: "Zoom Api",
      //   to: "Admin Zoom Api",
      //   icon: "mdi-video-outline",
      // },
      {
        title: "Tickets",
        to: "Admin Tickets",
        icon: "mdi-ticket-outline",
      },
      // {
      //   title: "Sponsors",
      //   to: "Admin Sponsors",
      //   icon: "mdi-account-group-outline",
      // },
      {
        title: "Recycle Bin",
        to: "Admin Trash",
        icon: "mdi-delete-outline",
      },
      
      // {
      //   title: "Disqualified",
      //   to: "Admin Leaderboard Disqualified",
      //   icon: "mdi-gamepad-circle-outline",
      // },
    ],
  }),
  mounted() {},
  methods: {
    ...mapActions("admin", ["getTrashedStudentsAction"]),
  },
};
</script>